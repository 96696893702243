@import "mixins/index";
@import "uikit/_styles/common-styles";

.titleBlock {
    display: flex;
    width: 100%;
    color: #262626;
    margin-bottom: 8px;

    .description {
        color: #262626;
        font-size: 13px;
        line-height: 19px;
        opacity: 0.5;
    }
    &:hover {
        .title {
            color: #1280CE;
        }
    }
}

.cover {
    margin-right: 12px;
    width: 88px;
    height: 56px;
    background: #EAEDF3;
    border-radius: 5px;
    flex-shrink: 0;

    img {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
}


.mobileTitleBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    .logoContainer {
        margin-top: 8px;
        width: 100%;
        height: 77px;
        background: #EAEDF3;
        border-radius: 5px;
        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
        }
    }
}

.subheader {
    font-weight: 600;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #262626;
    opacity: 0.5;
    margin-bottom: rem(6px);
}

.mobileDelete {
    position: absolute;
    top: 16px;
    right: 24px;

    @media (min-width: 768px) {
        display: none !important;
    }
}
