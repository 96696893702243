@import "src/mixins/index.scss";

.article {
    display: flex;
    width: 100%;

    .articleWrapper {
        display: flex;
        width: 100%;
    }
    .articleContainer {
        width: 100%;
        height: calc(100svh - 57px);
        overflow: auto;

        &.withNewsTicker {
            height: calc(100svh - 86px);
        }
    }
    .articleHistory {
        width: 100%;
        max-width: 260px;
        height: calc(100svh - 57px);
        background: #FFFFFF;
        box-shadow: -2px 1px 2px rgba(0, 0, 0, 0.05);
        z-index: 1;

        &.withNewsTicker {
            height: calc(100svh - 86px);
        }
        .articleHistoryHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 54px;
            border-bottom: 1px solid #EAEDF3;

            h4 {
                padding-left: 16px;
                color: #262626;
                font-size: 14px;
                font-weight: 600;
                line-height: 150%;
            }
            .articleHistoryHeaderClose {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 54px;
                height: 100%;
                border-left: 1px solid #EAEDF3;
                cursor: pointer;
            }
        }
        .articleHistoryBody {
            display: flex;
            flex-direction: column;
            height: calc(100% - 54px);
            overflow: auto;

            .articleHistoryBodyItem {
                display: flex;
                flex-direction: column;
                padding: 16px;
                border-bottom: 1px solid #EAEDF3;
                transition: all 0.3s ease-in-out;
                cursor: pointer;

                &:hover, &.articleHistoryBodyItemActive {
                    background: rgba(18, 128, 206, 0.05);
                }
                h5 {
                    margin-bottom: 8px;
                    color: #262626;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                }
                .articleHistoryBodyItemContainer {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    img {
                        margin-right: 8px;
                        width: 16px;
                        height: 16px;
                        border-radius: 16px;
                        object-fit: cover;
                        overflow: hidden;
                    }
                    span {
                        color: #262626;
                        font-size: 12px;
                        line-height: 150%;
                    }
                }
                p {
                    color: rgba(38, 38, 38, 0.75);
                    font-size: 12px;
                    line-height: 150%;
                }
            }
        }
    }
}

.header {
    border-bottom: 1px solid var(--color-grayBlue);
}

.scrollTopBtn {
    position: absolute;
    bottom: 24px;
    right: 24px;
    font-weight: 500;
    color: #1280ce;
    text-align: center;
    cursor: pointer;

    div:first-of-type {
        margin-bottom: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        background-color: #1280CE;
        border-radius: 9999px;

        & svg path {
            fill: #fff;
        }
    }
}

.readBtnMobile {
    margin-right: 0 !important;
    margin-left: auto !important;
}

.restoreModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.65);
    z-index: 10;

    .restoreModal {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 570px;
        background: #FFFFFF;
        border: 1px solid #EAEDF3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

        .restoreModalHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 24px 0 24px;

            h2 {
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .restoreModalBody {
            padding: 32px;

            p {
                margin-bottom: 32px;
                color: #000000;
                font-size: 14px;
                line-height: 21px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        .restoreModalFooter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 16px 24px;
            border-top: 1px solid #EAEDF3;

            div {
                margin-right: 16px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.favoriteActive {
    svg path {
        fill: #1280ce;
    }
}

.search {
    position: sticky;
    top: 0;
    z-index: 11;
    border-bottom: 1px solid var(--color-grayBlue);
}
.postingReadVersion {
    position: sticky;
    top: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px;
    background: #FFFFFF;
    border: 2px solid #1280CE;
    border-radius: 5px;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.15);
    z-index: 10;

    .postingReadVersionContent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .postingReadVersionContainer {
            display: flex;
            align-items: center;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 24px;
                min-width: 62px;
                min-height: 62px;
                background: #1280CE;

                .desktop {
                    display: block !important;
                }
                .mobile {
                    display: none !important;
                }
                svg path {
                    fill: #FFFFFF;
                }
            }
            p {
                color: #262626;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
            }
        }
        .postingReadVersionShow {
            display: flex;
            align-items: center;
            margin-right: 24px;
            cursor: pointer;

            i {
                margin-right: 8px;

                svg path {
                    fill: #1280CE;
                }
            }
            span {
                color: #1280CE;
                font-size: 14px;
                font-weight: 500;
                line-height: 100%;
            }
        }
    }
    .postingReadVersionClose {
        padding: 22px 24px;
        height: 100%;
        border-left: 1px solid #EAEDF3;

        i {
            cursor: pointer;
        }
    }
}
.postingSnapshot {
    padding: 24px;
    background: #FFFFFF;
    border-bottom: 1px solid #EAEDF3;

    p {
        margin-bottom: 16px;
        color: #262626;
        font-size: 14px;
        font-weight: 600;
        line-height: 180%;
    }
    ul {
        display: flex;
        list-style-type: none;

        li {
            display: flex;
            flex-direction: column;
            margin-right: 40px;

            &:last-child {
                margin-right: 0;

                a {
                    color: #1280CE;
                    cursor: pointer;
                }
            }
            label {
                margin-bottom: 4px;
                color: rgba(38, 38, 38, 0.5);
                font-size: 13px;
                line-height: 180%;
            }
            span, a {
                color: #262626;
                font-size: 13px;
                font-weight: 500;
                line-height: 150%;
            }
        }
    }
}

.articlePostingRestoreButton svg path {
    fill: #7B8F9C;
}

.postingContent {
    background-color: #fff;

    .cover {
        width: 100%;
        height: calc((100vw - 168px) / 4);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        @media print {
            max-height: none !important;
            height: 100% !important;
        }
    }

    .desc {
        @include platform-spacing(padding);
        padding-top: rem(14px) !important;
        padding-bottom: rem(14px) !important;
        border-bottom: 1px solid var(--color-grayBlue);
        font-size: rem(14px);
        line-height: rem(21px);
        word-wrap: break-word;
    }

    .fileBlock {
        @include platform-spacing(margin-left);
        @include platform-spacing(margin-right);

        .blockText {
            font-size: rem(14px);
            font-weight: 600;
            color: var(--color-textBlack);
        }

        .blockFiles {
            padding: 1rem 0;
        }
    }
    .keywordsBlock {
        @include platform-spacing(margin-left);
        @include platform-spacing(margin-right);

        .blockText {
            font-size: rem(14px);
            font-weight: 600;
            color: var(--color-textBlack);
        }

        .blockKeywords {
            display: flex;
            flex-wrap: wrap;
            margin: 1rem 0;

            .blockKeyword {
                padding: 7px 6px;
                margin-right: 6px;
                background: #F5F5F5;
                border-radius: 100px;
                color: #262626;
                font-size: 13px;
                font-weight: 500;
                line-height: 14px;
                cursor: pointer;
            }
        }
    }

    &.mobileContent {
        .cover {
            height: auto;
        }
    }
}

@media (max-width: 1024px) {
    .article {
        flex-direction: column;

        .mobileHeader {
            .versionButton {
                width: 100%;

                button {
                    width: 100%;
                }
            }
        }
        .postingSnapshot {
            display: none;
        }
        .articleWrapper {
            .articleContainer {
                .postingReadVersion {
                    position: relative;
                    top: 0;
                    align-items: flex-start;
                    padding: 12px 12px 44px 12px;

                    .postingReadVersionContent {

                        .postingReadVersionContainer {
                            align-items: flex-start;

                            div {
                                margin-right: 8px;
                                padding: 0;
                                min-width: 26px;
                                min-height: 26px;
                                background: none;

                                .desktop {
                                    display: none !important;
                                }
                                .mobile {
                                    display: block !important;
                                }
                            }
                            p {
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 150%;
                            }
                        }
                        .postingReadVersionShow {
                            position: absolute;
                            bottom: 12px;
                            left: 12px;

                            i {
                                width: 16px !important;
                                height: 16px !important;
                            }
                            span {
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 150%;
                            }
                        }
                    }
                    .postingReadVersionClose {
                        padding: 0;
                        border: none;

                        i {
                            width: 24px !important;
                            height: 24px !important;

                            svg {
                                circle {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .cover {
                    margin-top: 0 !important;
                }
            }
        }
        .articleHistory {
            .articleHistoryHeader {
                h4 {
                    font-size: 13px;
                    font-weight: 500;
                }
                .articleHistoryHeaderClose {
                    width: 28px;
                    height: 28px;

                    i {
                        width: 7px !important;
                        height: 7px !important;
                    }
                }
            }
        }
    }
}
