@import 'src/mixins/index.scss';

.editor {
    position: relative;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    padding: 1px;

    &.error {
        border: 1px solid var(--color-red);
    }
}
.editorErrorText {
    color: var(--color-red);
    font-size: rem(12px);
    line-height: rem(16px);
    margin-top: 4px;
}

.editorMenu {
    width: 100%;
    border: 1px solid #eaf5fc;
    border-radius: 5px;
    background-color: #eaf5fc;
    padding: 4px;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
    }
}

.editorMenuGroup {
    display: flex;
    border-right: 1px solid #c1c6ca;
    padding-left: 10px;
    padding-right: 10px;

    >*+* {
        margin-left: 4px;
    }
}

.editorMenuButton {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-width: 30px;
    min-height: 30px;
    flex-shrink: 0;
    gap: 2px;
    border-radius: 5px;
    transition: background ease 0.2s;

    &.disabled {
        cursor: not-allowed;

        svg path {
            fill: #e2e5e9;
            stroke: #c1c6ca;
        }
    }

    &.grow {
        min-width: 100%;
        justify-content: flex-start;
    }

    &.selected, &.hover:hover {
        background-color: rgba(39,155,217, 0.1);
    }
}

.bubbleMenuButton {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    min-height: 20px;
}

.editorContentContainer {
    overflow: hidden;
}

.editorContentWrapper {
    position: relative;
    height: 600px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: auto;
    cursor: grab;
}
.editorContentOutside {
    flex-grow: 1;
    cursor: text;
}
.editorContent {
    position: relative;

    &.hide {
        display: none;
    }
}

.editorFooter {
    display: flex;
    justify-content: flex-end;
    padding: 6px 8px;
    background-color: #eaedf3;
    color: #878e9c;
    font-size: 10px;
}

.editorMenuTable {
    padding: 16px;

    .rows>*+* {
        margin-top: 4px;
    }

    .row {
        display: flex;

        &>*+* {
            margin-left: 4px;
        }
    }

    .cell {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        border: 1px solid #c1c6ca;

        &.active {
            background-color: #eaedf3;
        }
    }
}

.editorMenuHeading {
    height: 100%;
    cursor: pointer;
}

.popper {
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 6px;
    text-align: left;
    font-size: 0.8rem;
}

.modal {
    position: relative;
    padding: 0;
    display: inline-block;
    overflow: hidden;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
    max-width: 568px;
    background-color: #fff;
    border: 1px solid #eaedf3;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    .modalHeader {
        display: flex;
        justify-content: space-between;
        padding-top: rem(24px);
        padding-left: rem(24px);
        padding-right: rem(24px);

        h3 {
            font-size: rem(22px);
            font-weight: 500;
        }

        .closeIcon {
            cursor: pointer;
        }
    }

    .modalBody {
        display: flex;
        flex-wrap: wrap;
        padding: rem(32px);

        img,
        video {
            width: 100%;
        }
    }

    .modalFooter {
        display: flex;
        justify-content: flex-end;
        padding: rem(16px) rem(24px);
        border-top: 1px solid #eaedf3;

        >*:not(:last-child) {
            margin-right: rem(16px);
        }
    }
}

.modalOverlay {
    position: fixed;
    padding: rem(32px);
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(#275677, 0.65);
    z-index: 20;

    &::before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: '';
    }
}

.modalContent {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 570px;
    height: 490px;

    .title {
        display: flex;
        justify-content: space-between;
        height: rem(30px);
        font-size: 22px;
        padding-left: rem(32px);
        padding-right: rem(32px);
    }
}

.subMenu {
    position: relative;
}

.subMenuList {
    position: absolute;
    z-index: 100;
    right: 0;
    top: 32px;
    display: none;
    opacity: 0;
    padding: 12px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
    transition: opacity ease 0.3s;

    &.show {
        opacity: 1;
        display: block;
        transition: opacity ease 0.3s;
    }
    .MenuContentClose {
        display: none;
    }
    @media (max-width: 1024px) {
        position: fixed;
        top: unset;
        bottom: 0;
        padding-top: 0;
        padding-bottom: 41px;
        width: 100%;
        height: 281px;
        border: 1px solid #E8E8E8;
        border-radius: 5px 5px 0 0;
        overflow: auto;
        z-index: 99999;

        .MenuContentClose {
            position: fixed;
            bottom: 0;
            left: 0;
            display: block;
            padding: 10px 12px;
            width: calc(100% + 10px);
            background: #FFFFFF;
            border-top: 1px solid #E8E8E8;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }
    }
}

.subMenuHeader {
    color: rgba(51, 139, 194, 0.8);
    padding-top: 9px;
    padding-bottom: 9px;
}

.subMenuItem {
    width: 266px;
    display: flex;
    padding: 1.5px 6px;
    align-items: center;
    white-space: nowrap;
    gap: 6px;
    cursor: pointer;

    &:hover {
        background-color: rgba(39, 155, 217, 0.1);
    }
    @media (max-width: 1024px) {
        width: 100%;
        height: 40px;
    }
}

.levelsLeftColumn {
    width: 100%;
    max-width: 310px;
    margin-right: 32px;
    flex-shrink: 0;
}

.levelsRightColumn {
    width: 100%;
    padding-top: 110px;

    ul,
    ol {
        width: 100%;

        li {
            margin-bottom: 15px;
        }
    }
}

.levelsSelectWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    padding-bottom: 16px;
}

.levelsSelect {
    display: flex;

    &>div {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        color: rgba(38, 38, 38, 0.7);
        cursor: pointer;

        &.active {
            color: #338bc2;
            background-color: rgba(39, 155, 217, 0.1);
            border: 1px solid #338bc2;
        }
    }
}

.levelCounterWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    padding-bottom: 12px;
}

.fontSize, .levelCounter {
    min-width: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;
    padding-left: 6px;
    padding-right: 6px;
    border: 1px solid #338bc2;
    margin-left: 11px;
    margin-right: 11px;

    background: #fff;

    i {
        cursor: pointer;
    }

    span {
        line-height: 21px;
    }
}

.levelCounter {
    height: 32px;
    color: #338bc2;
}

.headingButton.editorMenuButton {
    display: inline;
    min-height: initial;
    min-width: 22px !important;
    color: #338bc2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0 !important;
    margin-left: 0 !important;

}

.fontFamily {
    display: inline;
    min-height: initial;
    width: 136px;
    max-width: 136px;
    color: #338bc2;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0 !important;
    margin-left: 0 !important;

    @media (max-width: 1024px) {
        margin-right: 0 !important;
    }
    @media (max-width: 450px) {
        max-width: 72px;
    }
}

.dropZone {
    width: 100%;
    height: 100%;
    padding: 23px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    border: 1px dashed #eaedf3;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    cursor: pointer;

    i {
        margin-bottom: 8px;
    }

    p {
        max-width: 320px;
        font-size: 13px;
        line-height: 20px;
        opacity: 0.55;
    }
}
.dropZoneButton {
    display: flex;
    justify-content: center;
    width: 100%;
}

.fileWrapper {
    padding-top: 21px;
    padding-bottom: 21px;
}

.file {
    display: flex;
    width: 100%;
    min-height: 78px;
    padding: 1rem;
    border: 1px solid var(--color-grayBlue);
    border-radius: 5px;
    transition: border-color ease 0.1s;
    cursor: pointer;

    &>div {
        flex-grow: 1;
    }

    .fileIcon {
        margin-right: rem(16px);
        flex-shrink: 0;
    }

    .name {
        display: flex;
        flex-basis: 100%;
        justify-content: space-between;
        margin-bottom: rem(4px);

        .text {
            color: #262626;
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            word-break: break-all;
        }

        .buttons {
            display: flex;
            margin-left: auto;

            img+img {
                margin-left: 1rem;
            }
        }
    }

    .size {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
    }

    .icon {
        margin-left: rem(16px);
        cursor: pointer;
    }
}

.diagram, .googleDoc {
    display: flex;
    margin-top: 20px;
    width: 100%;
    border-radius: 5px;
    background: rgba(39, 155, 217, 0.1);
}

.diagram {
    margin-top: 20px;
    padding: 31px;

    &.left {
        justify-content: flex-start;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }
}

.tabs {
    display: flex;
    width: 100%;
    padding-bottom: 12px;

    .tab {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.active {
            color: rgba(51, 139, 194, 0.8);
        }
    }
}

.colorsTab {
    height: 100%;


    .colorsContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 260px;

        .colorsRow {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 4px;

            .colorsCell {
                width: 20px;
                height: 20px;
                border-radius: 30px;
                cursor: pointer;
            }

            .whiteCell::after {
                content: '';
                display: block;
                position: absolute;
                inset: -1px;
                border: 1px solid #DADADA;
                border-radius: 30px;
            }

            .selected::after {
                content: '';
                display: block;
                position: absolute;
                inset: -3px -3px;
                border: 3px solid rgba(39, 155, 217, 0.3) !important;
                border-radius: 30px;
            }
        }
        @media (max-width: 1024px) {
            padding-bottom: 84px;
            width: 100% !important;
            height: 100%;
            max-height: 281px;
            overflow: auto;

            &.colorsContentNoPadding {
                padding-bottom: 0;
            }
            .colorsRow {
                justify-content: space-between;

                & > div {
                    margin-right: 16px;
                    width: 100%;

                    &:last-child {
                        margin-right: 0;
                    }
                }
                .colorsCell {
                    width: 100%;
                    max-width: 60px;
                    height: auto;
                    aspect-ratio: 1/1;
                }
            }
        }
    }
}

.anchorWrapper {
    color: #338bc2;
    .icon, .add {
        vertical-align: middle;
    }

    .icon svg path {
        stroke: rgba(236, 51, 35, 1);
    }

    .add svg path {
        cursor: pointer;
    }

    .edit svg path {
        stroke: #338bc2;
        fill: white;
    }

    .remove svg path {
        fill: red;
    }
}

.bubbleMenu {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px;
    border-radius: 4px;
    background: white;
    z-index: 1;
    cursor: default;

    @media (max-width: 1024px) {
        box-shadow: 0 8px 20px 0 #0000001F;
    }

    @media (max-width: 767px) {
        flex-direction: column;
    }

    .edit, .remove {
        padding: 6px;
        border-radius: 4px;

        &:hover {
            background-color: rgba(39, 155, 217, 0.1);
        }
    }
}

.visible {
    visibility: visible !important;
}

@media (max-width: 1024px) {
    [data-floating-ui-portal=""] {
        display: none;
    }
    [data-floating-ui-portal=""]:last-of-type {
        display: block;
    }
}

.menuItemColorPicker {
    padding: 0 !important;
    &:hover {
        color: inherit !important;
        background-color: #fff !important;
    }
}

.tooltip {
    position: fixed;
    display: flex;
    padding: 5px 9px;
    background-color: rgba(38,38,38, 0.8) !important;
    color: #fff;
    border-radius: 4px;

    &::after {
        content: '';
        display: block;


        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: calc(100%);
        border-width: 6px 6px 0;
        border-color: transparent;
        border-top-color: initial;
        border-style: solid;
        color: rgba(38,38,38, 0.8);
    }
}
