.stepWrapper {
    padding: 24px 24px 32px 24px;

    &.withNewsTicker {
        .step:last-child {
            min-height: calc(100vh - 156px);
        }
    }
    &.stepWrapperPreview {
        .step:last-child {
            min-height: calc(100vh - 226px);
        }
    }
    .step {
        padding-top: 16px;
        padding-bottom: 24px;
        border-bottom: 1px solid #eaedf3;

        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            min-height: calc(100vh - 128px);
            border-bottom: 0;
        }
        & > div:first-child {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .stepTitle {
            margin-bottom: 24px;
            white-space: break-spaces;
        }
        .stepRefresh {
            display: flex;
            color: #1280ce;
            cursor: pointer;

            i {
                margin-right: 8px;
            }
        }
        .stepButtons {
            display: flex;

            button {
                white-space: pre-wrap;
                text-align: left;
            }
        }
        .stepButtons > div:not(:last-child) {
            margin-right: 16px;
        }
    }
    .blockStepsComplete {
        padding-top: 24px;

        & > div:first-child {
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid #eaedf3;
            color: #000000;
            font-size: 14px;
            font-weight: 400;
            line-height: 150%;
        }
    }
}
.articlesWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 24px 16px;

    a {
        display: flex;
        padding: 16px 12px;
        gap: 8px;
        width: calc(100% / 3 - 11px);
        border: 1px solid #eaedf3;
        border-radius: 4px;
        color: #000000;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;

        &:hover {
            color: #1280CE;
        }
        img {
            width: 96px;
            height: 60px;
            border-radius: 4px;
            object-fit: cover;
        }
    }
}
.linksWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 4px;

    a {
        display: flex;
        align-items: center;
        gap: 8px;
        height: 36px;
        color: #1280CE;
        font-size: 13px;
        font-weight: 500;
        line-height: 150%;

        i {
            min-width: 16px;
        }
    }
}
.filesWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 24px 16px;

    & > div {
        display: flex;
        align-items: center;
        padding: 0 8px;
        gap: 8px;
        width: calc(100% / 3 - 11px);
        height: 56px;
        border: 1px solid #eaedf3;
        border-radius: 4px;
        color: #000000;
        font-size: 13px;
        font-weight: 400;
        line-height: 150%;
        cursor: pointer;

        &:hover {
            color: #1280CE;
        }
        i {
            min-width: 20px;
        }
    }
}
.imagesWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    gap: 24px 16px;

    & > div {
        width: calc(100% / 3 - 11px);
        background: #EBEBEB;
        border-radius: 4px;
        cursor: pointer;

        img {
            width: 100%;
            aspect-ratio: 16 / 9;
            border-radius: 4px;
            object-fit: contain;
        }
    }
}
.stepScrollBtn,
.stepRefreshBtn {
    position: fixed;
    bottom: 16px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background: #1280CE;
    cursor: pointer;
}
.stepRefreshBtn {
    bottom: 64px;
}
