@import "mixins/index";
@import "uikit/_styles/common-styles";

.filtersOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(26, 26, 26, .65);
    z-index: 110;
}

.filters {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 420px;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: -35px 0 50px rgba(0, 0, 0, 0.03);
    overflow: auto;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 24px 0 24px;

        h3 {
            margin-bottom: 0;
            margin-left: 0;
            color: #262626;
            font-size: 22px;
            font-weight: 500;
            line-height: 30px;
        }
    }

    .body {
        padding: 32px 24px 110px 24px;

        .row {
            margin-bottom: 24px;

            &:last-child:after {
                display: none;
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background: #EAEDF3;
                margin-top: 24px;
            }

            h4 {
                margin-bottom: 4px;
                font-size: 13px;
                line-height: 18px;
                color: #262626;
                opacity: 0.75;
            }

            .main {
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: #262626;
                margin-bottom: 16px;
            }

            .actions > div > div, .users > div > div {
                height: auto;
            }

            .dateRange {
                margin-top: 16px;

                & > div:first-child {
                    margin-bottom: 16px;
                }
            }

            .reset {
                padding: 0;
                background: transparent;
                border: none;
                color: #1280CE;
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
                margin-top: 16px;
            }

            .filterAction {
                display: flex;
                align-items: center;
                padding: 0;
                background: transparent;
                border: none;
                color: #1280CE;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                margin-bottom: 16px;

                .textFilterAction {
                    margin-left: 6px;
                    text-align: left;
                }

            }

            .tagItem {
                margin-bottom: 4px;
            }
            .textInfoTag {
                font-size: 13px;
                line-height: 18px;
                color: #262626;
                opacity: 0.5;
                display: block;
            }
            .Radio {
                margin-bottom: 16px;
                display: block;
            }
        }
    }

    .footer {
        display: flex;
        position: fixed;
        bottom: 0;
        padding: 12px 24px;
        width: 420px;
        border-top: 1px solid #EAEDF3;
        background: #FFFFFF;

        div {
            width: 420px;

            button {
                width: 180px;
                height: 40px;
                font-weight: 500;
                font-size: 14px;
            }

            button:first-child {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }
    }

    &.mobileFilters {
        .body {
            padding-bottom: 137px;
        }
        .footer {
            flex-wrap: wrap;
            width: 100%;
            div {
                button {
                    width: 100%;
                }
            }
        }
    }

    .actionsSearch {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: #1280CE;
        margin-bottom: 16px;
        cursor: default;
        span {
            cursor: pointer;
        }
    }
}

.mobile {
    .autoComleteContainer {
        width: auto;
        position: fixed;
        left: 3px;
        right: 3px;
        .resultContainer {
            margin-top: rem(24px);
        }
    }
}
