@import 'src/mixins/index.scss';

.postingWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    height: 100%;
    background-color: #fff;
    overflow: auto;
}

.header {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 18px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    .headerMain {
        flex: 1 1 auto;
        min-width: 0;
        .headerTitle {
            font-weight: 600;
            font-size: 24px;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .titleTest {
            @include header-large;
            position: absolute;
            visibility: hidden;
            height: auto;
            width: auto;
            white-space: nowrap;
          }
    }
}

.scriptHeader {
    display: flex;

    .actions {
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 2.5rem;
        padding-right: 24px;
    }
}

.description {
    padding: 24px;
    border-top: 1px solid #eaedf3;
    border-bottom: 1px solid #eaedf3;
    overflow-wrap: break-word;
    line-height: normal;
}

.tabsWrapper {
    position: sticky;
    top: -24px;
    height: 38px;
    z-index: 10;

    ul {
        display: flex;
        background: #ffffff;
        border-bottom: 1px solid #eaedf3;
        @include invisible-scroll();

        li {
            position: relative;
            padding: 13px 24px;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 12px;
            opacity: 0.5;
            cursor: pointer;
            flex-shrink: 0;

            &:hover,
            &.active {
                color: #1280ce;
                opacity: 1;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #1280ce;
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }
}

.playbackWrapper {
    height: 100%;
}
