@import 'src/mixins/index.scss';

.pageWrapper {
    display: flex;

    &.mobile {
        .contentWrapper {
            margin-left: 0;
        }
    }
}

.tree {
    position: fixed;
    width: 100%;
    max-width: rem(280px);
    height: calc(100vh - 58px);
    background-color: #fff;
    padding-right: 4px;

    &.withNewsTicker {
        height: calc(100vh - 85px);
    }

    &.treeHidden {
        position: absolute;
        top: 0;
        left: -1000px;
    }

    .hideButton {
        position: absolute;
        top: -1px;
        right: -1px;
        z-index: 999;
        display: flex;
        align-items: center;
        padding: 7px;
        height: 41px;
        border: 1px solid #eaedf3;
        cursor: pointer;

        .icon {
            transform: rotate(180deg);
        }
    }
}

.contentWrapper {
    position: relative;
    width: 100%;
    height: calc(100svh - 57px);
    margin-left: 280px;
    border-left: 1px solid #EAEDF3;
    overflow: hidden;

    &.withNewsTicker {
        height: calc(100vh - 85px);
    }

    & > div {
        height: 100%;
    }

    .showButton {
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 12;
        width: 19px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border: 1px solid #eaedf3;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer;
    }
    &.contentWithoutTree {
        margin-left: initial;
        border-left: 0;
    }
}
