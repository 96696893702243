@import "src/mixins/index.scss";

$btn-color: #1280CE;

.tree {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .search {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(40px);
        border-bottom: 1px solid #EAEDF3;
        padding: 0 0 0 1rem;

        input {
            width: 100%;
            border: 0;
            font-size: 13px;
            font-weight: normal;
            line-height: 18px;
        }

        .hideButton {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 100%;
            border-left: 1px solid #EAEDF3;
            cursor: pointer;
        }
        .icon {
            transform: rotate(180deg);
        }
    }

    .groupActions {
        display: flex;
        height: 2.5rem;
        align-items: center;
        padding: 0 1rem;
        border-bottom: 1px solid #EAEDF3;

        .actions {
            display: flex;
            flex-grow: 1;
            div + div {
                margin-left: 1rem;
            }
        }

        .close {
            display: flex;
        }
    }

    .toolbar {
        display: flex;
        height: rem(24px);
        margin: rem(8px);

        .btn {
            display: flex;
            align-items: center;
            font-size: 11px;
            font-weight: 500;
            line-height: 11px;
            color: $btn-color;
            justify-content: center;
            flex-basis: 50%;
            flex-shrink: 1;
            align-items: center;
            cursor: pointer;

            i {
                margin-top: 2px;
                margin-right: 8px;
            }
        }

        .btn + .btn {
            border-left: #EAEDF3 1px solid;
        }
    }

    .allBtn {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 36px;
        height: 32px;
        width: 100%;
        background: transparent;
        border: none;
        color: #1280CE;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;

        &.allBtnActive, &:hover {
            background: rgba(39, 155, 217, 0.05);
        }

        &.allBtnActive {
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 2px;
                height: 32px;
                background: #1280CE;
            }
        }

        i {
            margin-right: 10px;

            svg {
                width: 12px;
                height: 12px;

                path {
                    fill: #1280CE;
                }
            }
        }
    }

    .sizerContainer {
        flex-grow: 1;

        & > div:first-child {
            width: 100% !important;

            & > div > div {
                width: 100% !important;
            }
        }
    }

    .noTree {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding-top: rem(48px);

        i {
            margin-bottom: rem(8px);

            svg {
                fill: #7B8F9C;
            }
        }

        div {
            width: 100%;
            color: rgba(#262626, 0.5)
        }
    }
}
