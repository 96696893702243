@import "src/mixins/index.scss";

.project {
    position: relative;
    background: #FFFFFF;

    .head {
        display: flex;
        padding: 25px;

        .avatar {
            flex-shrink: 0;
            margin-right: 24px;
            width: 120px;
            height: 120px;
            background-size: cover;
            border-radius: 120px;

            &.small {
                width: 32px;
                height: 32px;
            }
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            width: 100%;

            .title {
                display: flex;
                width: 100%;

                h1 {
                    margin: 0 8px 8px 0;
                    padding: 0;
                    color: #262626;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 33px;
                    word-break: break-word;
                }

                .hideButton {
                    font-size: 13px;
                    line-height: 12px;
                    padding-bottom: 14px;
                    align-self: flex-end;
                    color: rgba(#262626, 0.5);
                    cursor: pointer;
                }
                .navigation {
                    display: flex;
                    margin-left: auto;

                    @media (min-width: 768px) and (max-width: 1024px) {
                        margin-right: 24px;
                    }
                    a {
                        margin-right: 20px;
                    }

                    div {
                        margin-right: 20px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    .favorites {
                        svg path {
                            fill: #1280CE;
                        }
                    }
                }
            }

            .projectInfo {
                height: 100%;
                overflow: hidden;

                &.hidden {
                    height: 0;
                }
            }

            p {
                margin-bottom: 16px;
                color: #262626;
                font-size: 14px;
                font-weight: normal;
                line-height: 21px;
            }

            .manager {
                display: flex;
                flex-direction: column;

                span {
                    margin-bottom: 2px;
                    color: #262626;
                    font-size: 13px;
                    line-height: 19px;
                    opacity: 0.5;
                }

                a {
                    color: #1280CE;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                }
            }
        }
    }
}

.mobileProject {
    position: relative;
    padding: rem(24px) rem(16px);
    background-color: var(--color-white);

    .titleBlock {
        display: flex;
        max-width: calc(100% - 28px);

        .avatar {
            min-width: 32px;
            min-height: 32px;
            width: 32px;
            height: 32px;
            background: #EAEDF3;
            background-size: cover;
            border-radius: 120px;
            margin-right: rem(10px);
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }

        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            display: flex;
            align-items: center;
            color: #262626;
        }
    }

    .desc {
        margin-top: rem(8px);
    }

    .manager {
        display: flex;
        flex-direction: column;
        margin-top: rem(16px);

        span {
            margin-bottom: 2px;
            color: #262626;
            font-size: 13px;
            line-height: 19px;
            opacity: 0.5;
        }

        a {
            color: #1280CE;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
        }
    }

    .navigationMenu {
        position: absolute;
        top: 24px;
        right: 16px;
    }
}

.projectMenuWrapper {
    position: relative;
    height: 38px;
    padding: 0 1rem;
    //@include invisible-scroll-wrapper();

    .projectMenuFogLeft, .projectMenuFogRight {
        position: absolute;
        top: 0;
        height: 100%;
        width: 20%;
        z-index: 11;
        pointer-events: none;
        transition: all 0.3s ease-in-out;
    }
    .projectMenuFogLeft {
        left: 0;
        background: linear-gradient(.75turn, transparent, 10%, white);
    }
    .projectMenuFogRight {
        right: 0;
        background: linear-gradient(.25turn, transparent, 10%, white);
    }
    ul.projectMenu {
        position: sticky;
        top: 0;
        display: flex;
        background: #FFFFFF;
        border-bottom: 1px solid #EAEDF3;
        z-index: 10;
        @include invisible-scroll();

        #scriptsTab {
            @media (max-width: 1024px) {
                display: none;
            }
        }

        li {
            position: relative;
            padding: 13px 24px;
            color: #262626;
            font-size: 13px;
            font-weight: 500;
            line-height: 12px;
            opacity: 0.5;
            cursor: pointer;
            flex-shrink: 0;

            &:hover, &.active {
                color: #1280CE;
                opacity: 1;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #1280CE;
                    border-radius: 5px 5px 0 0;
                }
            }
        }
    }
}
