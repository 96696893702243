@import "mixins/index";
@import "uikit/_styles/common-styles";

.modal {
    position: absolute;
    outline: none;
    overflow: unset;
}
.modalContent {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 570px;
    height: 490px;

    .title {
        display: flex;
        justify-content: space-between;
        height: rem(30px);
        font-size: 22px;
        padding-left: rem(32px);
        padding-right: rem(32px);
    }

    .dropZone {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px dashed #EAEDF3;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        cursor: pointer;
        margin-top: 25px;

        & p {
            text-align: center;
            margin-top: 8px;
        }
    }

    .uploading {
        transform: translateY(50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
