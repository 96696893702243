@import "src/mixins/index.scss";

.header {
    display: flex;
    width: 100%;
    background-color: #fff;

    .goBackButton {
        @media print {
            display: none !important;
        }
    }
    .mobileContent {
        display: none;
        margin: rem(16px);
        flex-direction: column;
        width: calc(100% - 88px);
        flex-grow: 1;

        @media (max-width: 767px) {
            display: flex;
        }
        .top {
            display: flex;
            align-items: center;
            margin-bottom: rem(12px);
            width: 100%;

            .goBackButton {
                margin-right: rem(16px);
            }
            .titleContent {
                display: flex;
                flex-direction: column;

                .title {
                    @include header-large;
                    word-break: break-word;
                }
            }
        }
        .info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .documentType {
                display: flex;
                align-items: center;
                margin-right: rem(12px);
                margin-bottom: rem(8px);

                i {
                    margin-right: rem(4px);
                }
            }
            .user {
                margin-right: rem(12px);
                margin-bottom: rem(8px);
                color: #1280CE;
                font-weight: 500;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: rem(8px);
                    background-color: #EAEDF3;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    flex-shrink: 0;
                }
            }
            .priorityLabel {
                margin-right: rem(12px);
                margin-bottom: rem(8px);
            }
            .date {
                display: flex;
                align-items: center;
                margin-bottom: rem(8px);
                color: var(--color-textBlack);
                font-size: rem(13px);
                line-height: rem(19.5px);

                i {
                    margin-right: rem(4px);
                }
            }
        }
        .bottom {
            display: flex;
            align-items: center;
            margin-top: rem(8px);

            .priorityLabel {
                margin-right: rem(8px);
            }
            .lastAction {
                display: flex;
                align-items: center;

                .userLink {
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: rem(4px);
                    }
                    .user {
                        color: #1280CE;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .desktopContent {
        display: none;
        flex-direction: column;
        margin: rem(16px) rem(24px);

        @media (min-width: 768px) {
            display: flex;
        }
        .titleBar {
            display: flex;
            align-items: center;
            margin-bottom: rem(12px);
            width: 100%;

            .goBackButton {
                margin-right: rem(16px);
            }
            .titleContent {
                display: flex;
                flex-direction: column;

                .title {
                    @include header-large;
                    word-break: break-word;
                }
            }
        }
        .infoBar {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-left: rem(48px);

            .documentType {
                display: flex;
                align-items: center;
                margin-right: rem(20px);
                margin-bottom: rem(12px);

                i {
                    margin-right: rem(4px);
                }
            }
            .user {
                margin-right: rem(20px);
                margin-bottom: rem(12px);
                color: #1280CE;
                font-weight: 500;

                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: rem(8px);
                    background-color: #EAEDF3;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    flex-shrink: 0;
                }
            }
            .date {
                display: flex;
                align-items: center;
                margin-right: rem(20px);
                margin-bottom: rem(12px);
                color: var(--color-textBlack);
                font-size: rem(13px);
                line-height: rem(19.5px);

                i {
                    margin-right: rem(4px);
                }
            }
            .priorityLabel {
                margin-right: rem(20px);
                margin-bottom: rem(12px);
            }
            .lastAction {
                display: flex;
                align-items: center;
                margin-right: rem(20px);
                margin-bottom: rem(12px);

                .userLink {
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: rem(4px);
                    }
                    a {
                        margin-bottom: 0;
                        margin-right: 0;
                        color: #1280CE;
                        font-weight: 500;
                    }
                }
            }
            .viewers {
                display: none;
                align-items: center;
                margin-bottom: rem(12px);

                @media (min-width: 1024px) {
                    display: flex;
                }
                svg {
                    margin-right: rem(4px);
                    fill: var(--color-green);
                }
            }
        }
    }
}
