@import 'src/mixins/index.scss';

:root {
    --color-gray-dark: #262626;
    --color-gray-light: #9f9f9f;
    --color-secondary-yellow: #ffe05a;
    --color-secondary-red: #e06169;
    --color-secondary-pale: #edf1f5;
    --color-primary-green: #60b480;
    --color-primary-blue: #279bd9;
    --color-black: #000;
    --color-white: #fff;
    --color-black-30: rgba(51, 51, 51, 0.3);
    --color-primary-outline: rgba(37, 143, 251, 0.3);
    --color-alert: var(--color-secondary-red);
    --color-alert-20: rgba(236, 57, 61, 0.2);
    --color-input-border: #e8e8e8;
    --color-input-border-focus: #1280ce;
    --color-input-border-hover: #1280ce;
    --color-input-placeholder-hover: #262626;
    --color-input-bg-hover: white;
    --color-table-row-hover: #eff8f2;
    --color-table-header: #949699;
    --color-table-text: var(--color-input-border-focus);
    --color-green: #50b678;
    --color-blue: #1280ce;
    --color-yellow: #ffe05a;
    --color-red: #ff6973;
    --color-grayBlue: #eaedf3;
    --color-textBlack: #262626;
    --color-iconGray: #7b8f9c;
    --color-backgroundGray: #fbfbfd;
    --color-whiteHover: #fdfdfd;
    --color-whitePressed: #fbfbfb;
    --color-grey: #f5f5f5;
    --color-blueHover: #188fe3;
    --color-bluePressed: #0f7ac7;
    --color-greenHover: #52c57f;
    --color-greenPressed: #42b26e;
    --color-stroke: #e8e8e8;
}

// @font-face {
//   font-family: AGBenguiat;
//   src: url('./benguiat.ttf') format('truetype');
// }
// @font-face {
//   font-family: PFBagueSansPro;
//   src: url('./PFBagueSansPro-Regular.otf') format('opentype');
// }

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 400;
    src: url('static/fonts/NotoSans-Regular.ttf') format('opentype');
}

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 500;
    src: url('static/fonts/NotoSans-Medium.ttf') format('opentype');
}

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 600;
    src: url('static/fonts/NotoSans-SemiBold.ttf') format('opentype');
}

@font-face {
    font-family: 'NotoSans';
    font-style: normal;
    font-weight: 700;
    src: url('static/fonts/NotoSans-Bold.ttf') format('opentype');
}

* {
    box-sizing: border-box;
    font-family: 'NotoSans', sans-serif;
    font-synthesis: none;
    moz-font-feature-settings: 'kern';
    webkit-font-smoothing: antialiased;
    moz-osx-font-smoothing: grayscale;
}

body {
    overflow: hidden;
}

body > iframe {
    pointer-events: none;
}

body,
input {
    font-family: 'NotoSans', sans-serif;
    color: var(--color-gray-dark);
    text-decoration: none;
    font-size: rem(14px);
    line-height: rem(16px);
}

a {
    text-decoration: none;
    color: var(--color-gray-dark);
    &:focus {
        outline: none;
    }
}

::-webkit-scrollbar {
    width: rem(6px);
    height: rem(6px);
    background: transparent;
    margin-right: rem(5px);
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    height: auto;
    background-color: rgba(51, 51, 51, 0.2);
    border-radius: rem(4px);
}

.react-tagsinput {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;

    span {
        input {
            width: 150px;
        }
    }
}

.react-tagsinput--focused {
    border: 2px solid var(--color-blue);
}

.react-tagsinput-tag {
    background-color: #e8e8e8;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    color: var(--color-gray-dark);
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: ' ×';
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
}

mark {
    background: #FFE05A;
    color: #262626;
    font-weight: 600;
}

.activeSearchMark {
    background: orange;
    color: black;
}

.accordion {
    .accordion__item {
        .accordion__heading {
            div {
                display: flex;
                align-items: center;
                padding: 32px 0;
                border-bottom: 1px solid #eaedf3;
                font-size: 14px;
                font-weight: 600;
                line-height: 21px;

                &[aria-expanded='true']:before {
                    margin-top: -3px;
                }

                &:before {
                    height: 7px;
                    width: 7px;
                    margin-right: 16px;
                    border-bottom: 1px solid #7b8f9c;
                    border-right: 1px solid #7b8f9c;
                }
            }
        }
    }
}

.tippy-content {
    padding: rem(4px) rem(8px);
    word-wrap: break-word;
}

.tippy-box {
    background-color: rgba(38,38,38, 0.8) !important;
    color: var(--color-white);
}

.tippy-arrow {
    color: rgba(38,38,38, 0.8) !important;
}

.tippy-arrow:before {
    color: rgba(38,38,38, 0.8) !important;
}

.tippy-box[data-placement="left"] .tippy-arrow:before {
    top: 3px;
    border-width: 5px 0 5px 7px !important;
}

.slick-slide {
    margin-right: 24px !important;
}
.slick-slide:first-of-type:not(:last-of-type) {
    //margin-left: 24px !important;
}
@media (max-width: 1024px) {
  .slick-slide:first-of-type:not(:last-of-type) {
      margin-left: 0 !important;
  }
}
.slick-slide:last-of-type {
    margin-right: 0 !important;
}
.slick-list {
    margin-right: -24px !important;
}

.slick-track {
    @include platform-spacing(margin-left);
    @include platform-spacing(margin-right);
}

.overlay {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: center;
    white-space: nowrap;
    background: rgba(38, 38, 38, 0.65);

    &::before {
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        content: '';
    }
}

.modal {
    position: relative;
    margin-top: 16px;
    margin-bottom: 48px;
    display: inline-block;
    overflow: hidden;
    width: calc(100% - 48px);
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    outline: none;

    @media (min-width: 992px) {
        margin-bottom: 0;
        width: initial;
    }
}
@media (max-width: 1024px) {
    [data-tippy-root] {
        visibility: hidden !important;
    }
    #editor-content [data-tippy-root] {
        visibility: visible !important;
    }
}

#scriptingMenuItem {
    @media (max-width: 1024px) {
        display: none;
    }
}

.react-flow__attribution {
  display: none !important;
}

#articlePreviewModal #fileViewDialog,
#newsPreviewModal #fileViewDialog {
    top: 0 !important;
    height: 100% !important;
}
.react-toast-notifications__container {
    z-index: 99999 !important;
}

.Toastify__toast {
    margin-bottom: 0;
}
