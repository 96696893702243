@import "src/mixins/index.scss";

@keyframes backdrop-in { 0% { opacity: 0; } 100% { opacity: 1; } }

$toolbar-height: 56px;

.imagePreviewModal {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    width: 100%;
    height: 100%;
    transform: none !important;
    user-select: none;
    overflow: auto !important;

    .preloaderWrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .preloaderIcon {
        width: 26px;
        height: 26px;
    }

    .transformWrapper {
        width: 100%;
        height: calc(100% - $toolbar-height * 2);
    }

    .headerToolbarWrapper {
        height: $toolbar-height;
        width: 100%;
    }

    .headerToolbar {
        position: absolute;
        width: 100%;
        z-index: 999;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        padding: 13px;
        background-color: rgba(26, 26, 26, 0.875);
        opacity: 0;
        transform: translateY(-200%);
        transition: 0.8s ease-in-out;
        will-change: transform, opacity;

        &.headerToolbarLoaded {
            opacity: 1;
            transform: translateY(0);
        }

        &:hover {
            span {
                opacity: 1;  
            }
        }

        .text {
            font-weight: 500;
            font-size: 22px;
            line-height: 30px;
            align-self: flex-end;
        }

        span {
            cursor: pointer;
            border: 1px solid #FFFFFF;
            padding: 4px;
            border-radius: 4px;
            transition: 0.215s ease-out;
            opacity: 0.8;
            position: relative;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                display: flex !important;
                justify-content: center;
                align-items: center;
            }

            svg {
                path {
                    transition: 0.215s ease-out;
                    stroke: #FFFFFF;
                }
            }
    
            &:hover {
                border-color: var(--color-primary-blue);
                svg {
                    path {
                        fill: var(--color-primary-blue);
                        stroke: var(--color-primary-blue);
                    }
                }
            }
        }

        span:last-child {
            i {
                width: 12px !important;
                height: 12px !important;
            }
        }
    }

    .footerToolbarWrapper {
        height: $toolbar-height;
        width: 100%;
    }

    .footerToolbar {
        position: absolute;
        display: flex;
        justify-content: center;
        width: 100%;
        z-index: 9999;
        bottom: 0;
        left: 0;
        transform: translateY(200%);
        gap: 8px;
        background-color: rgba(26, 26, 26, 0.875);
        padding: 13px;
        transition: 0.8s ease-in-out;
        opacity: 0;
        will-change: transform, opacity;

        &.footerToolbarLoaded {
            opacity: 1;
            transform: translateY(0);
        }

        &:hover {
            span {
                opacity: 1;  
            }
        }

        span {
            cursor: pointer;
            border: 1px solid #FFFFFF;
            padding: 4px;
            border-radius: 4px;
            transition: 0.215s ease-out;
            opacity: 0.8;

            svg {
                path {
                    transition: 0.215s ease-out;
                }
            }
    
            &:hover {
                border-color: var(--color-primary-blue);
                svg {
                    path {
                        fill: var(--color-primary-blue);
                    }
                }
            }
        }
    }

    .content {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: auto !important;
        user-select: none;
        pointer-events: none;
        opacity: 0;
        transition: 0.4s ease-out;
        transform: scale(0);
        transition-delay: 0.65s;
        will-change: transform, opacity;

        &.contentLoaded {
            pointer-events: all;
            opacity: 1;
            transform: scale(1);
        }

        &:active {
            cursor: grabbing;
        }
        
        img {
            width: inherit;
            height: inherit;
            display: block;
            margin: auto;
            background-color: #fff;
        }   
    }
}

.termModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.65);
    z-index: 99991;

    opacity: 0;

    animation: backdrop-in 0.5s ease-in;
    animation-fill-mode: forwards;

    .termModal {
        display: flex;
        flex-direction: column;
        margin: 0 !important;
        width: 100%;
        max-width: 570px;
        background: #ffffff;
        border: 1px solid #eaedf3;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        transform: none !important;

        .termModalHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 24px 24px 0 24px;

            h2 {
                color: #262626;
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
            }
        }
        .termModalBody {
            padding: 24px;
            color: #262626;
            font-size: 14px;
            font-weight: 500;
            line-height: 19px;
        }
    }
}

