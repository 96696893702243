$backdrop-base-color: rgba(38, 38, 38, 0);
$backdrop-highlight-color: rgba(38, 38, 38, 0.65);
$backdrop-transition-time: 0.85s;

$iframe-base-transformm: translateY(-105%);
$iframe-highlight-transformm: translateY(0);
$iframe-transition-time: 0.55s;

@keyframes backdrop-in { 0% { opacity: 0; } 100% { opacity: 1; } }

.embedDrawIoDialogModal {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    border: 0;
    padding: 0;
    opacity: 0;
    background-color: $backdrop-highlight-color;

    animation: backdrop-in $backdrop-transition-time ease-in;
    animation-fill-mode: forwards;

    .embedDrawIoDialogBody {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0;

        animation: backdrop-in $backdrop-transition-time ease-in;
        animation-fill-mode: forwards;
    }

    .embedDrawIoDialogPreloader {
        position: absolute;
        top: 40%;
    }

    .embedDrawIoDialogIframe {
        width: 92vw;
        border: 0;
        transition: $iframe-transition-time ease-in-out;

        &.editMode {
            height: 100vh;
            border-radius: 3px;
            transform: translateY(-105%);
        }

        &.viewerMode {
            height: 90vh;
            border-radius: 5px;
            transform: translateY(-120%);
        }

        &.animIn {
            transform: translateY(0);
        }

        &.editMode.animOut {
            transition-delay: 0.325s;
            transform: translateY(-105%);
        }

        &.viewerMode.animOut {
            transition-delay: 0.325s;
            transform: translateY(-120%);
        }
    }

    .embedDrawIoDialogMenu {
        position: absolute;
        top: 9px;
        right: 4.7vw;
        display: flex;
        gap: 5px;
        opacity: 0;
        transition: 0.35s ease-out;

        &.animIn {
            transition-delay: 0.75s;
            opacity: 1;
        }

        &.animOut {
            transition-delay: 0.15s;
            opacity: 0;
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #1280CE;
            padding: 5px;
            border-radius: 4px;
            background-color: #FFFFFF;
            cursor: pointer;
            transition: 0.215s ease-out;

            &:hover {
                filter: brightness(115%);
            }

            svg {
                width: 24px !important;
                height: 24px !important;
            }
        }
    }
}