.splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
}

.splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
    overflow: auto;
}

.splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    background-color: #ccc;
}

.splitter-layout .layout-splitter:hover {
    background-color: #bbb;
}

.splitter-layout.layout-changing {
    cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
}

.splitter-layout.splitter-layout-vertical {
    flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 4px;
    cursor: row-resize;
}

.splitter-layout {
    width: 100%;
}

.splitter-layout > .layout-splitter {
    width: 9px;
    border-left: 4px solid #fff;
    background-color: #eaedf3;
    border-top: 0;
    border-bottom: 0;
    border-right: 4px solid #fbfbfd;
}

.splitter-layout > .layout-splitter:hover {
    border-color: #eaedf3;
    background-color: #eaedf3;
}

.splitter-layout > .layout-pane:first-child {
    min-width: fit-content;
}

.splitter-layout > .layout-pane.layout-pane-hidden {
    min-width: initial;
}
