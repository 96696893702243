.inputPlaceholder {
    height: 20px;
    display: flex;
    align-items: center;
    padding-left: 9.5px;
    padding-right: 9.5px;
    border-radius: 4px;
    font-size: 13px;
    color: #338BC2;

    &:hover, &.active {
        background-color: rgba(#279BD9, 0.1);
    }
}
.input {
    input {
        display: flex;
        color: #338BC2 !important;
        border-color: #338BC2;
        min-width: 54px;
        height: 20px !important;
        font-size: 13px !important;
    }
}

.mobileWrapper {
    display: flex;
    gap: 6px;
}