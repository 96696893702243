@import 'src/mixins/index.scss';

.pageWrapper {
    padding-top: rem(24px);
    padding-bottom: rem(24px);
    padding-left: rem(24px);
    padding-right: rem(24px);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.header {
    font-size: rem(22px);
    font-weight: 500;
    @include platform-spacing(margin-bottom);
    line-height: rem(30px);
}

.tree {
    position: fixed;
    width: 100%;
    max-width: rem(280px);
    height: calc(100vh - 58px);
    overflow: auto;
    background-color: #fff;

    &.withNewsTicker {
        height: calc(100vh - 90px);
    }
}

.contentWrapper {
    // width: 100%;
    // padding: rem(24px);
    // margin-left: 280px;
    height: 100% !important;
    display: flex;
    flex-direction: column;
}

.pageTitle {
    padding-bottom: 24px;
}

.rowTitle {
    color: #262626 !important;
}

.desc {
    font-size: 13px;
    line-height: 150%;
    color: rgba(#262626, 0.5);
}

.loader {
    // position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 1;
}

.table {
    position: relative;
    background: #ffffff;
    border: 1px solid #eaedf3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

    .filter {
        padding: 24px;
        border-bottom: 1px solid #eaedf3;
    }

    .pagination {
        padding: 16px 24px;
        background: #ffffff;
        border-top: 1px solid #eaedf3;
        border-radius: 0 0 5px 5px;
    }
}
