@import 'src/mixins/index.scss';

.generalControlContainer {
    position: absolute;
    left: 153px;
    bottom: 0;
    width: 100px;
    height: 100px;
}
.generalControlButtonsContainer {
    position: absolute;
    z-index: 4;
    display: flex;
    right: 0;
    bottom: -45px;

    button {
        margin-right: 16px;
        white-space: nowrap;
    }
}
.createContainer {
    width: 100%;
    height: 100%;

    .pageContainer {
        display: flex;
        height: 100%;

        .constructorContainer {
            position: relative;
            width: 100%;
            height: calc(100% - 58px);
            max-width: calc(100% - 26.25rem);

            .headingContainer {
                width: 100%;
                border-bottom: 1px solid var(--color-grayBlue);
                background-color: white;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 24px 6px 24px;

                .infoContainer {
                    display: flex;

                    .navigation {
                        margin-right: 16px;
                    }
                    .info {
                        display: flex;
                        flex-direction: column;
                        margin-right: 16px;

                        .title, .titleEmpty {
                            margin-bottom: 4px;
                            color: #262626;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 150%;
                        }
                        .titleEmpty {
                            opacity: 0.5;
                        }
                        .destination, .destinationEmpty, .destination span {
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 100%;
                        }
                        .destinationEmpty {
                            opacity: 0.5;
                        }
                    }
                }
                .editContainer {
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    min-width: 120px;

                    i {
                        flex-shrink: 0;
                    }

                    span {
                        margin-left: 8px;
                        color: #1280ce;
                    }
                }
            }
        }
        .uiContainer {
            position: relative;
            width: 100%;
            max-width: rem(420px);
            background-color: #ffffff;
            padding: 24px;
            border-left: 1px solid var(--color-grayBlue);
            overflow: auto;

            .uiContainerEmpty {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                color: #000000;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                text-align: center;
                opacity: 0.65;
            }
            .stepContainer {
                display: flex;
                flex-direction: column;

                .stepHeading {
                    display: flex;
                    justify-content: space-between;
                }
                .stepHeadingLabel {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    margin-bottom: 16px;
                }
                .stepsConditionContainer {
                    .conditionCard {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        padding: 16px;
                        border: 1px solid #EAEDF3;
                        border-radius: 5px;
                        box-shadow: 0px 1px 3px 0px #00000008;
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 16px;
                        .conditionType {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 3px;
                            height: 100%;
                        }
                        .conditionInfo {
                            flex: 1 1 auto;
                            min-width: 0;
                            .conditionText {
                                margin-bottom: 4px;
                                font-weight: 600;
                                line-height: 18px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .nextStepText {
                                line-height: 18px;
                                opacity: 0.5;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                        .conditionBtn {
                            cursor: pointer;
                        }
                    }
                }
                .stepRemoveBtn {
                    color: #ff6973;
                    cursor: pointer;
                }
                .desc {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    margin-bottom: 16px;
                }
                .scriptInfoName {
                    textarea {
                        padding: 10px;
                        border-radius: 5px;
                        resize: none;
                        max-height: 155px;
                    }
                }
                .scriptInfoDescription {
                    margin-bottom: 24px;
                    textarea {
                        padding: 10px;
                        border-radius: 5px;
                        resize: none;
                        max-height: 140px;
                        font-size: 13px;
                    }
                }
                .scriptLocationPath {
                    max-height: 146px;
                    overflow: auto;
                    padding-right: 32px;
                }
                .conditionOptionSingleValue,
                .conditionOption {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
                .conditionOption {
                    padding: 8px 12px;
                    &:not([class*="conditionOptionActive"]):hover {
                      background-color: #DEEBFF;
                    }
                }
                .conditionOptionActive {
                    background-color: #2684FF;
                    color: var(--color-white);
                }
                .conditionOptionMarker{
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                }
                .conditionOptionMarkerNeutral{
                    background-color: var(--color-grey);
                }
                .conditionOptionMarkerPositive{
                    background-color: var(--color-green);
                }
                .conditionOptionMarkerNegative{
                    background-color: var(--color-red);
                }
                .inputScriptName {
                    margin-bottom: 16px;

                    label::after {
                        content: '*';
                        display: inline-block;
                        font-weight: 400;
                        color: var(--color-red);
                    }
                }
                .conditionNodeSelect {
                  margin-bottom: 12px;
                }
                .attachContainer {
                    display: flex;
                    margin-bottom: 30px;

                    div {
                        margin-right: 30px;
                    }
                }
                .controlsContainer {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    position: absolute;
                    bottom: 14px;
                    right: 24px;
                    width: 100%;

                    .cancelButton {
                        margin-right: 16px;
                    }
                }
                .addConditionContainer {
                    display: flex;
                    cursor: pointer;
                    span {
                        color: #1280ce;
                    }
                    .addButtonContainer {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 18px;
                        height: 18px;
                        background: #1280ce;
                        border-radius: 100px;
                        color: white;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
.fileWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: rem(8px) rem(32px) rem(8px) rem(8px);
    border: 1px solid var(--color-grayBlue);
    border-radius: 5px;
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;
}
.file {
    display: flex;

    .fileIconWrapper {
        padding-right: rem(12px);
    }
    .descWrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
    }
    .fileName {
        font-size: 13px;
        line-height: 18px;
        padding-bottom: rem(8px);
    }
    .fileDescription {
        color: rgba(#262626, 0.5);

        .fileType {
            text-transform: uppercase;
        }
    }
}
.linkWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    padding: rem(8px) rem(32px) rem(8px) rem(8px);
    margin-bottom: 16px;
    border: 1px solid var(--color-grayBlue);
    border-radius: 5px;
    position: relative;
    & > a {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .link {
        display: flex;
    }
    .linkIcon {
        margin-right: 8px;
    }
    .linkMain {
        width: 323px;
        .linkName {
            padding-bottom: 4px;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            color: var(--color-blue);
            overflow-wrap: break-word;
        }
        .linkUrl {
            font-size: 13px;
            line-height: 18px;
            color: rgba(#262626, 0.5);
            overflow-wrap: break-word;
        }
    }
}
.articleWrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    width: 100%;
    padding: rem(16px);
    border: 1px solid var(--color-grayBlue);
    border-radius: 5px;
    margin-bottom: 16px;
    position: relative;
}
.articleTitle {
    width: calc(100% - 16px);
    word-wrap: break-word;
    white-space: break-spaces;
}
.iconWrapper {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 8px;
}
.imagesWrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.imageRemove {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    background-color: rgba(#000, 1);
    cursor: pointer;
}
.image {
    position: relative;
    margin-bottom: 16px;
    width: calc(50% - 4px);
    background: #EBEBEB;
    border-radius: 4px;

    img {
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
        object-fit: contain;
    }
}
