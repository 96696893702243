.embedDrawIoImageWrapper {
    position: relative;
    cursor: default;
    overflow: visible;
    transition: 1.35s ease-out;
    margin: 16px 0;

    &.locked {
        opacity: 0.35;
        pointer-events: none;
    }

    &:hover > .embedDrawIoImage {
        background-color: #F4FAFD;
    }

    &:hover > .embedDrawIoImageMenu {
        visibility: visible;
        opacity: 1;
    }

    .embedDrawIoImage {
        position: relative;
        max-width: 100%;
        padding: 10px;
        border-radius: 0.5rem;
        transition: 0.25s ease-out;
        margin-top: -44px;
    }

    .embedDrawIoImageMenu {
        position: sticky;
        z-index: 99;
        width: 77px;
        top: 0;
        display: flex;
        gap: 2px;
        transition: 0.25s ease-out;
        visibility: hidden;
        opacity: 0;
        background-color: #EAF5FC;
        padding: 6px;
        border-radius: 4px;
        border: 1px solid #e0e6e9;
        box-shadow: rgba(0, 0, 0, 0.105) 1.35px 1.35px 3.15px;
    
        span {
            cursor: pointer;
            border: 1px solid #1280CE;
            background-color: #FFFFFF;
            padding: 5px;
            border-radius: 4px;
            transition: 0.215s ease-out;
    
            &:hover {
                filter: brightness(115%);
            }
        }
    }
}