@import 'src/mixins/index.scss';

.toggleTreeButton {
    position: fixed;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #eaedf3;
    border-top: 0;
    width: 39px;
    height: 39px;
    z-index: 2;

    i {
        transform: scale(-1, 1);
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        display: flex;
    }
}
.addPageWrapper {
    height: calc(100% - 57px);

    & > div {
        height: 100%;
    }
}

.base {
    position: relative;
    display: flex;

    .treeContainer {
        &.treeContainerHidden {
            display: none;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            position: fixed;
            width: 100%;
            height: 100%;
            background: rgba(38, 38, 38, 0.65);
            z-index: 2;
        }

        &.treeHidden {
            position: absolute;
            top: 0;
            left: -1000px;
        }
    }
    .tree {
        position: fixed;
        width: 100%;
        max-width: rem(280px);
        height: calc(100vh - 58px);
        overflow: auto;
        background-color: #fff;

        &.withNewsTicker {
            height: calc(100vh - 90px);
        }

        .hideButton {
            position: absolute;
            top: -1px;
            right: -1px;
            z-index: 999;
            display: flex;
            align-items: center;
            padding: 7px;
            height: 41px;
            border: 1px solid #eaedf3;
            cursor: pointer;
            
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    .noTree {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        width: 100%;
        padding-top: rem(48px);

        i {
            margin-bottom: rem(8px);

            svg {
                fill: #7b8f9c;
            }
        }

        div {
            width: 100%;
            color: rgba(#262626, 0.5);
        }
    }

    .contentUsers {
        width: 100%;
        height: calc(100svh - 57px);
        margin-left: 280px;
        border-left: 1px solid #eaedf3;

        @media (min-width: 768px) and (max-width: 1024px) {
            margin-left: 0;
        }

        .showButton {
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: 12;
            width: 19px;
            height: 41px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border: 1px solid #eaedf3;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
        }

        &.contentUsersWithoutTree {
            margin-left: initial;
            border-left: 0;
        }
        .usersUl {
            position: sticky;
            top: 0;
            z-index: 1;
            display: flex;
            background-color: #fff;
            border-bottom: 1px solid #eaedf3;

            li {
                position: relative;
                padding: 13px 24px;
                color: #262626;
                font-size: 13px;
                font-weight: 500;
                line-height: 12px;
                opacity: 0.5;

                &:hover,
                &.active {
                    opacity: 1;
                    a {
                        color: #1280ce;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background: #1280ce;
                        border-radius: 5px 5px 0 0;
                    }
                }
            }
        }

        .router {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            height: 100%;
        }
    }

    .tableWrapper {
        @include platform-spacing(padding);
        padding-top: rem(24px);
    }

    label.uploadLabel input[type='file'] {
        position: absolute;
        top: -1000px;
    }

    .uploadLabel {
        display: inline-flex;
        align-items: center;
        border: none;
        padding: rem(5px) rem(32px);
        background: #ddd;
        background: var(--color-primary-blue);
        border-radius: rem(5px);
        cursor: pointer;
        text-align: center;
        font-size: rem(14px);
        line-height: rem(16px);
        color: white;
        min-height: rem(40px);
        margin-right: rem(8px);
        margin-bottom: rem(8px);
    }

    &.mobile {
        .contentUsers {
            margin-left: 0;
        }
    }
}
