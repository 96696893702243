.mobileBack {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.65);
    z-index: 102;
}
.container {
    position: fixed;
    top: 64px;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 400px;
    max-height: calc(100vh - 105px);
    background: #FFFFFF;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    z-index: 999;

    &.mobile {
        top: unset;
        bottom: 16px;
        left: 16px;
        width: calc(100% - 32px);
    }
    &.newLine {
        top: 95px
    }
    .head {
        display: flex;
        align-items: center;
        padding:  0 24px;
        height: 48px;
        border-bottom: 1px solid #EAEDF3;
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        line-height: 150%;

        b {
            color: #262626;
            font-weight: 500;
        }
    }
    .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0;
        height: 48px;
        border-top: 1px solid #EAEDF3;
        color: #1280CE;
        font-size: 13px;
        font-weight: 500;
        line-height: 100%;
        cursor: pointer;
    }
    .empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;

        i {
            margin-bottom: 8px;

            svg path {
                fill: #7B8F9C;
            }
        }
        span {
            color: rgba(38, 38, 38, 0.5);
            font-size: 13px;
            line-height: 18px;
        }
    }
    .notifications {
        overflow-y: auto;

        .notification {
            display: flex;
            flex-direction: column;
            padding: 16px 24px;
            background: rgba(18, 128, 206, 0.03);
            border-bottom: 1px solid #EAEDF3;
            transition: all 1s ease-in-out;

            &.notificationRead {
                background: transparent;
            }
            &:last-child {
                border-bottom: none;
            }
            .notificationContent {
                display: flex;
                margin-bottom: 8px;

                img {
                    margin-right: 8px;
                    width: 32px;
                    height: 32px;
                    border-radius: 32px;
                }
                p {
                    color: #262626;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 150%;
                    word-break: break-word;

                    a {
                        display: inline;
                        color: #1280CE;
                    }
                }
            }
            .notificationComment {
                margin-bottom: 8px;
                padding: 12px;
                width: 100%;
                max-width: 650px;
                background: #FBFBFD;
                border-radius: 5px;
                color: #262626;
                font-size: 14px;
                line-height: 150%;
            }
            button {
                margin-bottom: 8px;
                margin-left: 40px;
            }
            .notificationTime {
                color: rgba(0, 0, 0, 0.5);
                font-size: 13px;
                line-height: 150%;
            }
        }
    }
    .loader {
        display: flex;
        justify-content: center;
    }
}
