@import 'src/mixins/index.scss';

$btn-color: #1280ce;

.tree {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    &.hidden {
        position: absolute;
        top: 0;
        left: -1000px;
    }

    .search {
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(40px);
        border-bottom: 1px solid #eaedf3;
        padding: 0 0 0 1rem;

        input {
            width: 100%;
            border: 0;
            font-size: 13px;
            font-weight: normal;
            line-height: 18px;
        }
        .hideButton {
            display: flex;
            align-items: center;
            padding: 7px;
            height: 100%;
            border-left: 1px solid #eaedf3;
            cursor: pointer;
        }
        .icon {
            transform: rotate(180deg);
        }
    }

    .toolbar {
        display: flex;
        height: rem(24px);
        margin: rem(8px);

        .btn {
            display: flex;
            font-size: 11px;
            color: $btn-color;
            justify-content: center;
            flex-basis: 50%;
            flex-shrink: 1;
            align-items: center;
            cursor: pointer;

            i {
                margin-right: 8px;
            }
        }

        .btn + .btn {
            border-left: #eaedf3 1px solid;
        }
    }

    .allBtn {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 36px;
        height: 32px;
        width: 100%;
        background: transparent;
        border: none;
        color: #1280ce;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;
        text-align: left;

        &.allBtnActive,
        &:hover {
            background: rgba(39, 155, 217, 0.05);

            &.allBtnActive {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2px;
                    height: 32px;
                    background: #1280ce;
                }
            }
        }

        i {
            margin-right: 10px;

            svg {
                width: 12px;
                height: 12px;

                path {
                    fill: #1280ce;
                }
            }
        }
    }

    .sizerContainer {
        flex-grow: 1;
    }
}
