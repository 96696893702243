@import "src/mixins/index.scss";

.buttonContainer {
    button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: rem(10px) rem(16px);
        border: none;
        border-radius: rem(5px);
        font-family: 'NotoSans', sans-serif;
        color: white;
        font-size: rem(14px);
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;

        i + span, i + p {
            margin-left: 8px;
        }
        i + p + span {
            margin-left: 4px;
        }
        &.small {
            font-size: rem(13px);
        }
        &.medium {
            font-size: rem(14px);
        }
        &.icon {
            padding: 0;
            width: 40px;
            height: 40px;
        }
        &.iconRound {
            padding: 0;
            width: 40px;
            height: 40px;
            border-radius: 100px;
        }
        &.base {
            background: #FFFFFF;
            border: 1px solid #E8E8E8;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
            color: #262626;

            svg path {
                fill: #262626;
            }
            &:hover {
                background: #F3F9FD;
                border: 1px solid #E8E8E8;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                color: #1280CE;

                svg path {
                    fill: #1280CE;
                }
            }
            &:active {
                background: #ECF5FB;
                border: 1px solid #E8E8E8;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
                color: #1280CE;

                svg path {
                    fill: #1280CE;
                }
            }
            &:disabled {
                background: #F5F5F5;
                border: 1px solid #E8E8E8;
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
                color: rgba(38, 38, 38, 0.5);

                svg path {
                    fill: rgba(38, 38, 38, 0.5);
                }
            }
        }
        &.link {
            padding: 0;
            border: unset;
            background: unset;
            box-shadow: unset;
            color: #1280ce;

            svg path {
                fill: #1280ce;
            }
            &:hover {
                border: unset;
                background: unset;
                box-shadow: unset;

                svg path {
                    fill: #1280ce;
                }
            }
        }
        &.blue {
            background: #1280CE;
            color: #FFFFFF;

            svg path {
                fill: #FFFFFF;
            }
            &:hover {
                background: #188FE3;
                box-shadow: 0 2px 4px rgba(18, 128, 206, 0.35);
            }
            &:active {
                background: #0F7AC7;
                box-shadow: none;
            }
            &:disabled {
                background: #F5F5F5;
                box-shadow: none;
                color: rgba(38, 38, 38, 0.5);

                svg path {
                    fill: rgba(38, 38, 38, 0.5);
                }
            }
        }
        &.green {
            background: #50B678;
            color: #FFFFFF;

            svg path {
                fill: #FFFFFF;
            }
            &:hover {
                background: #52C57F;
                box-shadow: 0 2px 4px rgba(80, 182, 120, 0.35);
            }
            &:active {
                background: #42B36E;
                box-shadow: none;
            }
            &:disabled {
                background: #F5F5F5;
                box-shadow: none;
                color: rgba(38, 38, 38, 0.5);

                svg path {
                    fill: rgba(38, 38, 38, 0.5);
                }
            }
        }
        &.red {
            background: #FF6973;
            color: #FFFFFF;

            svg path {
                fill: #FFFFFF;
            }
            &:hover {
                background: #FF7D85;
                box-shadow: 0 2px 4px rgba(255, 105, 115, 0.35);
            }
            &:active {
                background: #E06169;
                box-shadow: none;
            }
            &:disabled {
                background: #F5F5F5;
                box-shadow: none;
                color: rgba(38, 38, 38, 0.5);

                svg path {
                    fill: rgba(38, 38, 38, 0.5);
                }
            }
        }
        &.light {
            background: transparent;
            border: none;
            color: #1280CE;

            svg path {
                fill: #1280CE;
            }
            &:hover {
                color: #188FE3;

                svg path {
                    fill: #188FE3;
                }
            }
            &:active {
                color: #0F7AC7;

                svg path {
                    fill: #0F7AC7;
                }
            }
            &:disabled {
                color: rgba(38, 38, 38, 0.5);

                svg path {
                    fill: rgba(38, 38, 38, 0.5);
                }
            }
        }
        &.transparent {
            background: rgba(255, 255, 255, 0.2);
            color: #FFFFFF;

            svg path {
                fill: #FFFFFF;
            }
            &:hover {
                background: rgba(255, 255, 255, 0.3);
            }
            &:active {
                background: rgba(255, 255, 255, 0.25);
            }
            &:disabled {
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }
    &.fullWidth {
        width: 100%;

        button {
            width: 100%;
        }
    }
    &.disabled {
        button {
            cursor: not-allowed;
        }

        &.selected {
            .base {
                background: #DFF1FC;
                color: #87BFE7;
            }
            .green {
                background: #79B590;
                color: #ADD7BD;
            }
            .red {
                background: #E09297;
                color: #F3C6C9;
            }
        }
    }
}
