.codeBlockWithLineNumbers {

    pre {
        display: grid;
        grid-template-columns: auto 1fr; 
        line-height: 1rem;

        & > div {
            display: inline-block;
            pointer-events: none;
            user-select: none;
            font-family: 'NotoSans', sans-serif; 
            font-size: 0.8rem;
            text-align: right; 
            background: #DCE9F4; 
            color: #979692; 
            padding: 0.75rem 0.5rem 0.75rem 1rem; 
            margin: 1rem 0; 
            border-radius: 0.5rem 0 0 0.5rem; 
    
            span {
                user-select: none;
            }
        }

        code {
            display: inline-block;
            white-space: pre-wrap;
            border-radius: 0 0.5rem 0.5rem 0;
        }
    }
}