.dropdownMenuButton {
    display: flex;
    align-items: center;

    &:hover,
    &.active {
        border-radius: 4px;
        background-color: rgba(39, 155, 217, 0.1);
    }

    & > *:first-child {
        min-width: 20px;
        min-height: 20px;
    }
}

.dropdownMenuList {
    padding: 6px;
    &.inline {
        display: flex;
        gap: 2px;
    }
}
