@import "src/mixins/index.scss";
@import "src/uikit/_styles/common-styles";

.preview {
    max-height: calc(100vh - 102px);
    width: 70vw;
    overflow: hidden;
    transition: min-width ease-out 0.1s;

    &.expand {
        min-width: 99vw;
        transition: min-width ease-out 0.1s;
    }

    @media (max-width: 1024px) {
        max-height: calc(100vh - 74px);
        width: calc(100vw - 44px);
    }

    .menu {
        display: flex;
        width: 100%;
        padding: 1rem 1.5rem;
        height: 73px;
        border-bottom: 1px solid #EAEDF3;
        background-color: #fff;

        .desktop {
            display: block;
        }
        .mobile {
            display: none;
        }
        @media (max-width: 767px) {
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
        .buttonIcon {
            button {
                display: flex;
                padding-top: 0;
                padding-bottom: 0;
                align-items: center;
                height: 40px;

                i {
                    margin-right: 0.5rem;
                }
            }
            .backIcon {
                svg path {
                    fill: #7B8F9C;
                }
            }
        }
        .expandButton {
            @media (max-width: 1024px) {
                display: none;
            }
        }
    }

    .header {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #EAEDF3;
        padding: 8px 16px;
        align-items: center;

        .title {
            @include header-large;
            font-weight: 600;
            flex-grow: 1;

            @media (max-width: 1024px) {
                display: none;
            }
        }
    }

    .content {
        height: calc(100vh - 232px);
        overflow: auto;

        .mobileTitle {
            padding: 8px 16px;
            border-bottom: 1px solid #EAEDF3;
            color: #262626;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;

            @media (min-width: 1024px) {
                display: none;
            }
        }
        .desc {
            padding: 16px;
            border-bottom: 1px solid #EAEDF3;
        }

        .attachments {
            margin: 0 1.5rem;

            div:first-child {
                height: 1px;
                background-color: var(--color-grayBlue);
                margin: 2rem 0;
            }

            div:nth-child(2) {
                @include text-normal;
                font-weight: 600;
                line-height: 150%;
                margin-bottom: 1rem;
            }
        }

        .attachmentsBlock {
            margin: 0 1.5rem;
            margin-bottom: rem(16px);
        }

        .cover {
            width: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}
