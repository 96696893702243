@import "mixins/index";
@import "uikit/_styles/common-styles";

.tagList {
    span + span {
        margin-left: 0.5rem;
    }
}

.modalContent {
    display: flex;
    flex-direction: column;
    padding: 25px;
    width: 570px;
    height: 490px;
    position: relative;
    padding-bottom: 74px;

    .title {
        display: flex;
        justify-content: space-between;
        height: rem(30px);
        font-size: 22px;
    }

    .body {
        margin-top: 2rem;
        flex-grow: 1;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1rem;

        & div + div {
            margin-left: 1rem;
        }
    }
}
